<template>
  <div style="height: 100%; overflow: auto">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CustomScrollNative',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
